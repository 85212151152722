































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { ButtonCpt, ConfirmCpt } from "@/core/components/common";
import AppVue from "@/AppVue.vue";
import { AuditRecordFilterModel, AuditLogEventModelGridCollection } from "@/core/webapi";
import { ApiService } from "@/core/services";
import AddAuditRecordDialogFormCpt from "./AddAuditRecordDialogFormCpt.vue";

@Component({
  components: {
    ButtonCpt,
    AddAuditRecordDialogFormCpt,
    ConfirmCpt,
  },
})
export default class AuditRecordTableCpt extends AppVue {
  @Prop() forPkId?: number;
  @Prop() userId?: number;

  @Watch("currentAcademicYear")
  async onAcademicYearChanged() {
    await this.loadAuditRecords(1);
  }

  auditRecords = new AuditLogEventModelGridCollection();
  showDialog = false;
  showConfirm = false;
  hideMinorEvents = false;
  currentActivityId = 0;
  downloadingCsv = false;

  get currentPage() {
    return this.auditRecords && this.auditRecords.currentPage > 0 ? this.auditRecords.currentPage : 1;
  }

  async created() {
    await this.loadAuditRecords(this.currentPage);

    this.$eventHub.$on("status-updated", async () => await this.loadAuditRecords(this.currentPage));
  }

  async downloadCsv() {
    this.downloadingCsv = true;
    try {
      const response = await ApiService.audit().getAuditRecordsCsv(this.getAuditRecordFilterModel(1));
      const heading = "data:text/csv;charset=utf-8,";
      const sanitized = response.data.replace(/(^\[)|(\]$)/gm, "");
      const encoded = encodeURI(`${heading}${sanitized}`);

      const link = document.createElement("a");
      link.setAttribute("href", encoded);
      link.setAttribute("download", "ActivityHistory.csv");
      link.click();
    } finally {
      this.downloadingCsv = false;
    }
  }

  async confirmDeleteActivity(activityId: number) {
    this.currentActivityId = activityId;
    this.showConfirm = true;
  }

  async deleteActivity(isConfirmed: boolean) {
    try {
      if (!isConfirmed || !this.currentActivityId) return;

      this.isLoading = true;
      await ApiService.audit().deleteAcvity(this.currentActivityId);
      await this.loadAuditRecords(this.currentPage);
      this.$eventHub.$emit("ACTIVITY_LOG_UPDATED");
    } finally {
      this.currentActivityId = 0;
      this.isLoading = false;
      this.showConfirm = false;
    }
  }

  async loadAuditRecords(page: number) {
    this.isLoading = true;
    try {
      const result = await ApiService.audit().filterAuditRecord(this.getAuditRecordFilterModel(page));
      this.auditRecords = result.data;
    } finally {
      this.isLoading = false;
    }
  }

  getAuditRecordFilterModel(page: number) {
    return new AuditRecordFilterModel({
      page,
      forPkId: this.forPkId,
      forUserId: this.userId,
      pageSize: 20,
      sortBy: "CreatedAt",
      sortOrder: "desc",
      hideMinorEvents: this.hideMinorEvents,
      academicYearId: this.currentAcademicYear,
    });
  }

  async onChange(page: number) {
    await this.loadAuditRecords(page);
  }

  async onLogDialogClose(result: boolean) {
    this.showDialog = false;

    if (result) {
      await this.loadAuditRecords(this.currentPage);
    }
  }
}
