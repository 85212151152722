

















import { Component, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class LastActivityHoursItemCpt extends AppVue {
  @Prop({ required: true }) value: number;
  @Prop({ required: true }) label: string;
}
