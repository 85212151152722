


















































































import { Component, Prop, Watch } from "vue-property-decorator";
import {
  BaseFormComponent,
  ButtonCpt,
  InputDateTimeCpt,
  InputAutocompleteCpt,
  InputTextareaCpt,
  InputNumberCpt,
} from "@/core/components/common";
import { SimpleLookupModel, AuditLogEventModel } from "@/core/webapi";
import { ApiService, NotificationProvider } from "@/core/services";
import moment from "moment";

@Component({
  components: {
    ButtonCpt,
    InputDateTimeCpt,
    InputAutocompleteCpt,
    InputTextareaCpt,
    InputNumberCpt,
  },
})
export default class AddAuditRecordDialogFormCpt extends BaseFormComponent {
  @Prop() forPkId?: number;
  @Prop() userId?: number;
  @Prop() academicYearId?: number;
  @Prop() showDialog?: boolean;

  show = false;
  lookups: SimpleLookupModel[] = [];
  model = this.getNewModel();

  @Watch("showDialog")
  onShow() {
    this.show = this.showDialog;
  }

  async onSubmitForm(): Promise<void> {
    this.isLoading = true;
    try {
      this.model.forPkId = this.forPkId;
      this.model.forUserId = this.userId;
      this.model.academicYearId = this.academicYearId;

      await ApiService.audit().saveAuditRecord(this.model);
      this.$eventHub.$emit("ACTIVITY_LOG_UPDATED");
      NotificationProvider.success("Activity successfully saved");
      this.close(true);
    } catch (err) {
      if (err.response && err.response.data) {
        this.model.modelState = err.response.data.modelState;
      }
    } finally {
      this.isLoading = false;
    }
  }

  getNewModel() {
    return new AuditLogEventModel({
      timestamp: moment().local().toISOString(),
    });
  }

  close(result: boolean) {
    this.show = false;
    this.model = this.getNewModel();
    this.$emit("closed", result);
  }

  async created() {
    this.show = this.showDialog;
    await this.loadLookups();
  }

  async loadLookups() {
    this.isLoading = true;
    try {
      const response = await ApiService.audit().getAuditRecordType();
      this.lookups = response.data;
    } finally {
      this.isLoading = false;
    }
  }
}
