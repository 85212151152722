














































import { Component, Watch } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { LastActivityHoursModel } from "@/core/webapi";
import { ApiService } from "@/core/services";
import { LastActivityHoursItemCpt } from "@/modules/audit/components";

@Component({
  components: { LastActivityHoursItemCpt },
})
export default class LastActivityHoursCpt extends AppVue {
  model = new LastActivityHoursModel({
    hoursThisWeek: 0,
    hoursThisMonth: 0,
    hoursThisAcademicYear: 0,
  });

  @Watch("currentAcademicYear")
  async onAcademicYearChanged() {
    await this.initializeModel();
  }

  async created() {
    this.$eventHub.$off("ACTIVITY_LOG_UPDATED");
    this.$eventHub.$on("ACTIVITY_LOG_UPDATED", async () => await this.initializeModel());

    await this.initializeModel();
  }

  private async initializeModel() {
    this.isLoading = true;
    try {
      const result = await ApiService.audit().getLastAcvityHours(this.currentAcademicYear);
      this.model = result.data;
    } finally {
      this.isLoading = false;
    }
  }
}
