import { render, staticRenderFns } from "./LastActivityHoursItemCpt.vue?vue&type=template&id=10bebdda&scoped=true&"
import script from "./LastActivityHoursItemCpt.vue?vue&type=script&lang=ts&"
export * from "./LastActivityHoursItemCpt.vue?vue&type=script&lang=ts&"
import style0 from "./LastActivityHoursItemCpt.vue?vue&type=style&index=0&id=10bebdda&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10bebdda",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VFlex,VLayout})
